import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  logIn: {},
  forgot: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.primary,
    textDecoration: 'underline',
    display: 'block',
    marginTop: '1.6rem',
    marginBottom: '1.9rem',
  },
  requireStyle: {
    color: theme.colors.bgDark,
    fontSize: '1.2rem',
    marginBottom: '1.7rem',
    lineHeight: 1.14,
    '&:before': {
      content: '"*"',
      color: theme.colors.alert,
    },
  },
  orStyle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '2rem 0',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  inputs: {
    marginBottom: '1.6rem',
  },
  button: {
    marginBottom: '2rem',
  },
  getSmsButtonContainer: {
    marginTop: '1.6rem',
  },
}))

export default useStyles
