import React from 'react'
import useStyles from './login-style'
import {
  Layout,
  Loading,
  SEO,
} from '../../ui'

function LoginView({
  children,
  // the page will be ready after required data is preloaded
  pageReady,
  seoTitle,
}) {
  const {
    containerFluid,
    container,
  } = useStyles()

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo title */}
      <SEO title={seoTitle} />
      {pageReady ? (
        <div className={containerFluid}>
          <div className={container}>
            {children}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Layout>
  )
}

export default LoginView
