import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  forgotPassword: {
    '& a': {
      display: 'block',
      fontSize: '1.4rem',
      color: theme.colors.primary,
      marginTop: '1.6rem',
      marginBottom: '1.6rem',
      lineHeight: 1.14,

    },
    '& > span': {
      color: theme.colors.bgMedium,
      margin: 0,
      lineHeight: 1.14,
    },
    '& hr': {
      margin: '3rem 0',
    },
    '& > div + button': {
      marginTop: '1.6rem',
    },
  },
  requireStyle: {
    color: theme.colors.bgDark,
    fontSize: '1.2rem',
    marginBottom: '0.8rem',
    marginTop: '1.4rem',
    lineHeight: 1.14,
    '&:before': {
      content: '"*"',
      color: theme.colors.alert,
      marginRight: '0.3rem',
    },
  },
  orStyle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '2rem 0',
  },
  hasUppercase: {
    textTransform: 'uppercase',
    marginBottom: '0rem',
  },

}))

export default useStyles
