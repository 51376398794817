import _ from 'lodash'
import flow from 'lodash/fp/flow'
import keys from 'lodash/fp/keys'
import reduce from 'lodash/fp/reduce'

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react'
import URI from 'urijs'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import {
  cancelRequest,
  useAuth,
  useAddresses,
  useSystemSettings,
  useUser,
  useOmnitechApp,
  useOrderMethod,
} from 'react-omnitech-api'
import {
  useAlert,
  useAnalytics,
  useCart,
  useLink,
} from '../../hook'
import {
  getLoginFormInitialValues,
  getSocialMediaAvailable,
  transformLoginFormValidationSchema,
} from '../../helpers'
import LoginForm from './login-form'
import LoginView from './login-view'

function LoginController({
  location, pathContext,
}) {
  // prepare hook
  const alert = useAlert()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { fetchCountries } = useAddresses()
  const { getSystemSetting } = useSystemSettings()
  const { auth, createSession, setAuth } = useAuth()
  const {
    cartId,
    createCart,
    initCart,
    mergeCart,
  } = useCart()
  const { createUsersOmniAuthOtps } = useUser()
  const {
    orderMethod,
  } = useOrderMethod()
  const { api } = useOmnitechApp()
  const { trackEvent } = useAnalytics()

  // hook with dependencies
  const preferredCountryCodes = getSystemSetting('country.preferred_country_codes')
  const formConfig = getSystemSetting('account.user_registration_fields', {})

  const socialAvailable = getSocialMediaAvailable(getSystemSetting)

  // internal states
  const [countriesEntities, setCountriesEntities] = useState([])
  const [defaultCountryCallingCode, setDefaultCountryCallingCode] = useState('')
  const [formDisabled, setFormDisabled] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false)
  // TODO: will change to use system setting to config what login approaches will be supported
  const [loginApproach, setLoginApproach] = useState('email')

  // local variable
  const redirectUrl = useMemo(() => _.get(location, 'state.redirectUrl'), [location])
  const callbackState = useMemo(() => _.get(location, 'state.callbackState'), [location])
  const seoTitle = t('screens.login.seo.title')

  // transform the countries to usable format in select
  const countryCallingCodeOptions = useMemo(() => (
    countriesEntities.map((country) => ({
      label: country.callingCode,
      value: country.callingCode,
    }))
  ), [countriesEntities])

  // useMemo for caching variables
  const formInitialValues = useMemo(() => (
    getLoginFormInitialValues({
      approach: loginApproach,
      defaultCountryCallingCode,
    })
  ), [defaultCountryCallingCode, loginApproach])

  const formValidationSchema = useMemo(() => (
    transformLoginFormValidationSchema({ approach: loginApproach })
  ), [loginApproach])

  const requiredFields = useMemo(() => (
    flow(
      keys,
      reduce((result, current) => ({
        ...result,
        [current]: true,
      }), {}),
    )(formInitialValues)
  ), [formInitialValues])

  /**
   * fetchCountriesApi
   * function to call api and fetch countries for country calling code options
   */
  const fetchCountriesApi = useCallback(async () => {
    try {
      const fetchCountriesApiQuery = getSystemSetting('api.v2.addresses.countries.registration.ecom.query', {})
      const { countries } = await fetchCountries({
        params: {
          pageSize: 999,
          ...fetchCountriesApiQuery,
        },
        arrayFormat: 'brackets',
      })
      const countriesCodes = _.split(preferredCountryCodes, ' ')
      const firstCountryCode = _.head(countriesCodes)
      const firstCountry = _.find(countries, { alpha2: firstCountryCode }) || _.first(countries)
      setCountriesEntities(countries)
      setDefaultCountryCallingCode(_.get(firstCountry, 'callingCode'))
      setFormDisabled(false)
      setPageReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSystemSetting, fetchCountries])

  /**
   * handle SmsTokenButton callbacks
   */
  function onRequestSmsTokenSuccess() {
    setShowVerificationCodeInput(true)
  }
  function onRequestSmsTokenError(error) {
    // TODO: handle error
    console.warn('registration :: send sms :: error :: ', error)
  }

  /**
   * handleMergeCart
   * after user registration success, if a guest cart
   */
  async function handleMergeCart() {
    const options = {
      params: {
        includes: ['cart_line_properties'],
      },
    }
    try {
      await mergeCart(options)
    } catch (error) {
      // continue the login process
      console.warn('[Project] handleMergeCart error: ', error)
    }
  }

  /**
   * handleSignIn
   *
   * @param {*} values, object contain all value from input
   */
  async function handleCreateSession(values) {
    alert.remove()
    setFormDisabled(true)

    const createSessionFields = _.keys(requiredFields)
    let sessionData = _.pick(values, createSessionFields)

    if (!_.isEmpty(sessionData.phone)) {
      sessionData = {
        ..._.omit(sessionData, ['countryCallingCode', 'localPhoneNumber']),
      }
    }

    // prepare api call payload
    const data = {
      session: {
        approach: loginApproach,
        ...sessionData,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      const { session } = await createSession(data)

      // update auth token and user id
      await setAuth({
        ...auth,
        ..._.pick(session, ['authToken', 'userId']),
      })

      // create cart instead of merge cart if cart state is isolated
      if (_.isEqual(_.get(initCart, 'state'), 'isolated')) {
        // skip create cart on dine in mode
        if (!_.isEqual(_.get(orderMethod, 'commerceType'), 'dineIn')) {
          await createCart({
            params: {
              includes: [
                'cart_shipments',
                'cart_shipments.inventory_store',
              ],
            },
          })
        }
      } else if (!_.isEmpty(cartId)) {
        // merge guest cart to user cart
        await handleMergeCart()
      }

      try {
        trackEvent('customerLogin', {}, {
          user: _.pick(sessionData, ['email', 'phone']),
        })
      } catch (ex) {
        // do nothing
      }

      // handle redirect to different page after sign in successfully
      navigate(
        _.isEmpty(redirectUrl) ? '/account/' : redirectUrl,
        {
          replace: true,
          state: callbackState,
        },
      )
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    try {
      await handleCreateSession(values)
    } catch (error) {
      // registration only return general error, does not has validation error
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    } finally {
      actions.setSubmitting(false)
    }
  }

  /**
   * onLoginApproachChange
   *
   * Formik onSubmit callback
   *
   * @param {*} value form phone/email from input
   */
  const onLoginApproachChange = useCallback((approach) => {
    setLoginApproach(approach)
  }, [])

  /**
   * onSocialSignIn
   *
   * redirect facebook
   * TODO implement logical for other sacial platforms
   */

  async function onSocialSignIn(provider) {
    alert.remove()
    const url = new URI(location.href)
    // use a cross-platform application like NGROK to test in localhost
    const extraParams = _.isEmpty(redirectUrl) ? '/account/' : redirectUrl
    const urlRedirect = `${url.protocol()}://${url.host()}/${pathContext.locale}/oauth/login?extraParams=${extraParams}`

    // prepare api call payload
    const data = {
      omniAuthOtp: {
        provider,
        mode: 'redirect',
        redirect: false,
        redirectUrl: urlRedirect,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      const { omniAuthOtp } = await createUsersOmniAuthOtps(data)
      const urlAPI = _.get(api, 'host')
      const urlAuth = _.get(omniAuthOtp, 'requestPhasePath')

      const newUrl = `${urlAPI}${urlAuth}`
      // handle redirect to different page after sign in successfully
      navigate(
        newUrl,
        { replace: false },
      )
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  const onRegisterClick = () => {
    // pass through state to register form
    navigate(
      '/registration/',
      _.pick(location, ['state']),
    )
  }

  /**
   * redirect to account page if user is already logged in
   */
  useEffect(() => {
    if (auth.userId) {
      navigate('/account/', { replace: true })
    }
  }, [])

  /**
   * redirect to account page if user is already logged in
   */
  useEffect(() => {
    if (!_.isEmpty(formConfig)) {
      if (_.get(formConfig, 'password.required')) {
        if (_.get(formConfig, 'email.required')) {
          setLoginApproach('email')
        } else {
          setLoginApproach('phone_password_v2')
        }
      } else {
        setLoginApproach('phone_and_sms_token')
      }
    }
  }, [formConfig])

  /**
   * get countries for country call code option
   */
  useEffect(() => {
    fetchCountriesApi()

    return () => {
      cancelRequest.cancelAll(['fetchCountries'])
    }
  }, [fetchCountriesApi])

  /**
   * cancel api call when leaving the page
   */
  useEffect(() => () => (
    cancelRequest.cancelAll(['createSession'])
  ), [])

  const viewProps = {
    pageReady,
    seoTitle,
  }

  const formPorps = {
    countryCallingCodeOptions,
    formDisabled,
    loginApproach,
    requiredFields,
    showVerificationCodeInput,
    socialAvailable,
    onRegisterClick,
    onRequestSmsTokenError,
    onRequestSmsTokenSuccess,
    onLoginApproachChange,
    onSocialSignIn,
  }

  return (
    <LoginView {...viewProps}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validateOnChange
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        <LoginForm {...formPorps} />
      </Formik>
    </LoginView>
  )
}

export default LoginController
