/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useState } from 'react'
import {
  ErrorMessage,
  Field,
  useFormikContext,
} from 'formik'
import { isSafari } from 'react-device-detect';
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import combineClassNames from '../../../../helpers/combineClassNames'
import useStyles from './email-or-phone-input-style'
import arrowDownIcon from '../../../../assets/icons/icon_dropdown_arrow.svg'

const EmailOrPhoneInputView = (props) => {
  const { t } = useTranslation()
  const [phoneEmailValue, setPhoneEmailValue] = useState('')
  const {
    setFieldValue,
    values,
  } = useFormikContext()
  const {
    countryCallingCodeOptions,
    loginApproach,
    onLoginApproachChange,
  } = props
  const styles = useStyles({ isSafari })

  const selectProps = {
    name: 'countryCallingCode',
    options: countryCallingCodeOptions,
    className: styles.select,
    classNamePrefix: styles.select,
    components: {
      DropdownIndicator: (componentProps) => (
        <components.DropdownIndicator {...componentProps}>
          <img src={arrowDownIcon} alt="" />
        </components.DropdownIndicator>
      ),
    },
  }

  const inputName = loginApproach === 'phone_password_v2' ? 'phone' : 'email'

  const setEmailOrPhoneValues = (e) => {
    const value = _.get(e, 'target.value', '')
    if (/^(\+)?(?:[ 0-9()-]){1,24}$/.test(value)) {
      let localPhoneNumber = _.replace(value, /[\s()-]/g, '')
      if (localPhoneNumber.length > 1 && localPhoneNumber.charAt(0) === '0') {
        localPhoneNumber = localPhoneNumber.substr(1, localPhoneNumber.length)
        setPhoneEmailValue(localPhoneNumber)
        setFieldValue('phone', `${values.countryCallingCode}${localPhoneNumber}`)
      } else {
        setPhoneEmailValue(localPhoneNumber)
        setFieldValue('phone', `${values.countryCallingCode}${localPhoneNumber}`)
      }
      onLoginApproachChange('phone_password_v2')
    } else {
      setFieldValue('email', value)
      setPhoneEmailValue(value)
      onLoginApproachChange('email')
    }
  }

  return (
    <div
      className={styles.phoneOrEmail}
    >
      <label
        htmlFor="emailOrPhone"
        className={combineClassNames([styles.inputLabel, styles.inputLabelRequired])}
      >
        {t('screens.login.form.phoneOrEmail')}
      </label>
      <div className={styles.phoneOrEmailInner}>
        <div
          className={combineClassNames([
            styles.codeSelect,
            loginApproach === 'phone_password_v2' && styles.codeSelectShow,
          ])}
        >
          <Select
            {...selectProps}
            value={_.find(countryCallingCodeOptions, { value: _.get(values, 'countryCallingCode') })}
            onChange={({ value }) => {
              setFieldValue('countryCallingCode', value)
              setFieldValue('phone', `${value}${phoneEmailValue}`)
            }}
          />
        </div>
        <Field
          className={styles.phoneOrEmailTextInput}
          id="emailOrPhone"
          name="emailOrPhone"
          autoComplete={!_.isEmpty(values.phone) ? 'tel-local' : 'email'}
          required
          value={phoneEmailValue}
          onChange={(e) => setEmailOrPhoneValues(e)}
          onBlur={(e) => setEmailOrPhoneValues(e)}
        />
      </div>
      <ErrorMessage component="p" className={styles.errorMessage} name={inputName} />
    </div>
  )
}

export default EmailOrPhoneInputView
