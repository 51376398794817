/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  useFormikContext,
} from 'formik'
import useStyles from './login-form-style'
import {
  Input,
  InputPhone,
  Button,
  Link,
  SmsTokenButton,
} from '../../ui'
import {
  EmailOrPhoneInput,
} from './components'

const LoginForm = ({
  countryCallingCodeOptions,
  requiredFields,
  formDisabled,
  loginApproach,
  showVerificationCodeInput,
  socialAvailable,
  onSocialSignIn,
  onRegisterClick,
  onRequestSmsTokenError,
  onRequestSmsTokenSuccess,
  onLoginApproachChange,
}) => {
  const { t } = useTranslation()
  const {
    isSubmitting,
    isValid,
    setFieldValue,
    submitForm,
    values,
  } = useFormikContext()

  const handlePhoneChange = ({ countryCallingCode, localPhoneNumber }) => {
    setFieldValue('countryCallingCode', countryCallingCode)
    setFieldValue('localPhoneNumber', localPhoneNumber)
    const phone = !_.isEmpty(countryCallingCode) && !_.isEmpty(localPhoneNumber)
      ? `${countryCallingCode}${localPhoneNumber}`
      : ''
    setFieldValue('phone', phone)
  }

  const handleSubmitForm = () => {
    submitForm()
  }

  // styles
  const styles = useStyles()

  return (
    <div className={styles.logIn}>
      <h3 className={styles.title}>{t('screens.login.signIn')}</h3>
      { _.some(socialAvailable, ['available', true])
        && (
        <>
          {
            _.map(socialAvailable, (socialMedia) => {
              if (socialMedia.available) {
                return (
                  <Button
                    key={socialMedia.name}
                    addFacebook
                    fontWeight={600}
                    size="1.6"
                    hasIconleft
                    dark
                    padding="1.25rem"
                    background={socialMedia.name}
                    text={t('screens.login.facebook')}
                    onClick={() => onSocialSignIn(socialMedia.name)}
                  />
                )
              }
              return null
            })
            }
          <p className={styles.orStyle}>
            -
            {' '}
            {t('screens.login.or')}
            {' '}
            -
          </p>
        </>
        )}
      <Form noValidate>
        <div className={styles.inputs}>
          {
            loginApproach === 'phone_and_sms_token' ? (
              <>
                <InputPhone
                  formik
                  label={t('screens.login.form.phone', { context: 'label' })}
                  name="phone"
                  placeholder={t('screens.login.form.phone', { context: 'placeholder' })}
                  required
                  defaultValue={{
                    countryCallingCode: values.countryCallingCode,
                  }}
                  countryCallingCodeOptions={countryCallingCodeOptions}
                  onChange={handlePhoneChange}
                />
                <div className={styles.getSmsButtonContainer}>
                  <SmsTokenButton
                    dark
                    disabled={_.isEmpty(values.localPhoneNumber)}
                    phone={`${_.get(values, 'countryCallingCode', '')}${_.get(values, 'localPhoneNumber', '')}`}
                    onSuccess={onRequestSmsTokenSuccess}
                    onError={onRequestSmsTokenError}
                  />
                </div>
                {
                  showVerificationCodeInput
                    ? (
                      <Input
                        formik
                        label={t('screens.registration.form.token')}
                        name="token"
                        required={requiredFields.token}
                      />
                    )
                    : ''
                }
              </>
            ) : (
              <>
                <EmailOrPhoneInput
                  countryCallingCodeOptions={countryCallingCodeOptions}
                  loginApproach={loginApproach}
                  onLoginApproachChange={onLoginApproachChange}
                />
                <Input
                  formik
                  label={t('screens.login.form.password')}
                  placeholder={t('screens.login.form.password', { context: 'placeholder' })}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                />
              </>
            )
          }
        </div>
        <Link to="/reset-password/" className={styles.forgot}>{t('screens.login.forgot')}</Link>
        <p className={styles.requireStyle}>{t('screens.login.required')}</p>
        <Button
          dark
          disabled={formDisabled || isSubmitting || !isValid}
          text={t('screens.login.buttons.submit')}
          onClick={handleSubmitForm}
          className={styles.button}
        />
        <Button
          border
          text={t('screens.login.buttons.register')}
          onClick={onRegisterClick}
          className={styles.button}
        />
      </Form>
    </div>
  )
}

export default LoginForm
